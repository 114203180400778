import moment from 'moment';
import {
  CURRENCY,
  IAddOrder,
  ICurrencyConversion,
  REQUEST_METHOD,
  ROLS,
} from '../../utils/types';
import {CorporateData, UpdateCorporateData} from '../../utils/verify_types';
import {host, instance} from '../constants/instance';
import axios from 'axios';

const url =
  host === 'monibag.com' || host === 'api.monibag.com'
    ? `https://apigateway.monibag.com/v2`
    : `https://uat-apigateway.monibag.com/v2`;

export default class API_SERVICE {
  static async _getCustomerCount() {
    return await instance({
      url: `/core/internal/analysis/getCount/byActiveCustomer`,
      method: REQUEST_METHOD.GET,
    });
    // return axios.get(`${url}/core/internal/analysis/getCount/byActiveCustomer`);
  }

  static async _getCountByRole() {
    return await instance({
      url: `internal/get/account/count/byRole`,
      method: REQUEST_METHOD.GET,
      headers: {Role: ROLS.AGENT},
    });
  }

  static async _getAllByCurrency(currencyISO: string) {
    console.log(currencyISO, 'here');

    return await instance({
      url: `core/internal/agentFund/getAllTotal/byCurrency`,
      method: REQUEST_METHOD.GET,
      headers: {'Currency-ISO-3': currencyISO},
    });
  }

  static async _getAllByBankEx(currencyISO: string) {
    console.log(currencyISO, 'here');

    return await instance({
      url: `core/internal/bankExchangeFund/getAllTotal/byCurrency`,
      method: REQUEST_METHOD.GET,
      headers: {'Currency-ISO-3': currencyISO},
    });
  }

  static async _getTotalFundsPerBank() {
    return await instance({
      url: `/core/internal/bankFund/getAll?page=1&size=100`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getTopFiveBanksPerTrxn(limit?: number) {
    return await instance({
      url: `core/internal/analysis/getAllTotal/byReceiverBankCurrency?limit=${limit}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllUsers(
    page?: number,
    nextPageToken?: string,
    limit?: number
  ) {
    if (nextPageToken) {
      return await instance({
        url: `internal/account/getAll?page=${page}&size=${limit}`,
        method: REQUEST_METHOD.GET,
        headers: {'Next-Page-Token': nextPageToken},
      });
    } else {
      return await instance({
        url: `internal/account/getAll?page=${page}&size=${limit}`,
        method: REQUEST_METHOD.GET,
        // headers: { 'Next-Page-Token': nextPageToken },
      });
    }
  }

  static async _getUserDetails(email?: string) {
    return await instance({
      url: `internal/account/get`,
      method: REQUEST_METHOD.GET,
      headers: {Email: email},
    });
  }

  static async _getUserByRole(page: number, size: number, roles?: string[]) {
    const queryString = roles?.map((value) => `roles=${value}`).join('&');
    return await instance({
      url: `internal/account/getAll/byRoles?${queryString}&page=${page}&size=${size}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllRoles() {
    return await instance({
      url: `internal/roleRight/getAllAvailableRoles`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getUserQuestionaire(email: string) {
    return await instance({
      url: `core/internal/onboardingQuestionnaire/get`,
      method: REQUEST_METHOD.GET,
      headers: {Email: email},
    });
  }

  static async _getUserDetailsStatus(email?: string) {
    return await instance({
      url: `core/internal/onboardingProfile/get`,
      method: REQUEST_METHOD.GET,
      headers: {Email: email},
    });
  }

  static async _getAllUsersByRole(page?: number, query?: string) {
    if (query !== '') {
      return await instance({
        url: `internal/account/getAll/byRoles?roles=${query?.toUpperCase()}&roles=${query?.toUpperCase()}&page=${page}&size=100`,
        method: REQUEST_METHOD.GET,
      });
    } else {
      return await instance({
        url: `internal/account/getAll/byRoles?roles=${query?.toUpperCase()}&roles=${query?.toUpperCase()}&page=${page}&size=100`,
        method: REQUEST_METHOD.GET,
      });
    }
  }

  static async _getDocumentsForReview(email?: string) {
    try {
      const response = await instance({
        url: `core/internal/document/metadata/getAll?page=1&size=100`,
        method: REQUEST_METHOD.GET,
        headers: {Email: email},
      });
      return response.data; // Ensure you're returning the data part of the response
    } catch (error) {
      // console.error('Error in _getDocumentsForReview:', error);
      throw error; // Ensure the error is thrown so React Query can handle it
    }
  }

  static async _getAllRolesandRights() {
    return await instance({
      url: `internal/roleRight/getAllAvailable`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getApiKey() {
    const baseURL =
      host === 'monibag.com'
        ? `https://apigateway.monibag.com/v2`
        : process.env.REACT_APP_GATEWAY_BASE_URL;
    console.log(baseURL, 'kjjjjkjjk');

    return await instance({
      url: `${baseURL}/api-auth/get/gateway/key`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllThresholds() {
    return await instance({
      url: `core/internal/bankThresholdFund/getAll?page=1&size=100`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllAgentThresholds() {
    return await instance({
      url: `core/internal/agentThresholdFund/getAll?page=1&size=100`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getSingleThresholdByEmailCurrency(
    email: string,
    currency_iso_3: string
  ) {
    return await instance({
      url: `core/internal/agentThresholdFund/getAll/byAgent?page=1&size=100`,
      method: REQUEST_METHOD.GET,
      headers: {Email: email, 'Currency-ISO-3': currency_iso_3},
    });
  }

  static async _getUserCount() {
    return await instance({
      url: `core/internal/analysis/getCount/byActiveCustomer`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllPrefundBanksEnabled() {
    return await instance({
      url: `reference/preFundBank/getAll/enabled`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllPrefundBanks() {
    return await instance({
      url: `reference/internal/preFundBank/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAgentThresholdFund() {
    return await instance({
      url: `core/internal/agentThresholdFund/getAll?page=1&size=1000`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAgentPreFund() {
    return await instance({
      url: `core/internal/agentPreFund/getAll?page=1&size=1000`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getBankPreFund() {
    return await instance({
      url: `reference/internal/preFundBank/getAll/byIsEnabled?is_enabled=true`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllEnabledPrefundBanks() {
    return await instance({
      url: `reference/preFundBank/getAll/enabled`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getPrefundBankByStatus(is_enabled: boolean) {
    return await instance({
      url: `reference/internal/preFundBank/getAll/byIsEnabled?is_enabled=${is_enabled}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getPrefundBank(
    bank_id: string,
    bank_account_currency_iso_3: string,
    bank_account_number: string
  ) {
    return await instance({
      url: `reference/preFundBank/get`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Pre-Fund-Bank-ID': bank_id,
        'Bank-Account-Currency-ISO-3': bank_account_currency_iso_3,
        'Bank-Account-Number': bank_account_number,
      },
    });
  }

  static async _getBank(bank_id: string, bank_account_currency_iso_3: string) {
    return await instance({
      url: `reference/bank/get?country_iso_3=${bank_account_currency_iso_3}&bank_id=${bank_id}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getFiliterAgentThresholdFund(
    email: string,
    currency_iso_3: string
  ) {
    return await instance({
      url: `core/internal/agentThresholdFund/getAll/byAgent?page=1&size=100`,
      method: REQUEST_METHOD.GET,
      headers: {
        Email: email,
      },
    });
  }

  static async _getFiliterAgentPreFund(email: string, reference_id: string) {
    return await instance({
      url: `core/internal/agentPreFund/get`,
      method: REQUEST_METHOD.GET,
      headers: {
        Email: email,
        'Reference-ID': reference_id,
      },
    });
  }

  static async _getFiliterBankPreFund(reference_id: string) {
    return await instance({
      url: `core/internal/bankPreFund/get`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Reference-ID': reference_id,
      },
    });
  }

  static async _getFiliterBankThresholdFund(
    bank_id: string,
    currency_iso_3: string
  ) {
    return await instance({
      url: `core/internal/bankThresholdFund/get`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Bank-ID': bank_id,
        'Currency-ISO-3': currency_iso_3,
      },
    });
  }

  static async _getCurrency(country_iso_3: string, currency_iso_3: string) {
    return await instance({
      url: `reference/currency/get?country_iso_3=${country_iso_3}&currency_iso_3=${currency_iso_3}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getSecretQuestion(secret_question_id: string) {
    return await instance({
      url: `reference/secretQuestion/get?secret_question_id=${secret_question_id}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getOnboardingRule(rule_id: string) {
    return await instance({
      url: `reference/internal/onboardingRule/get?rule_id=${rule_id}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getEntityResponse(entity_id: string, response_code: string) {
    return await instance({
      url: `reference/entityResponse/get?entity_id=${entity_id}&response_code=${response_code}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getEntity(entity_id: string) {
    return await instance({
      url: `reference/entity/get?entity_id=${entity_id}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getCountry(bank__currency_iso_3: string) {
    return await instance({
      url: `reference/country/get?country_iso_3=${bank__currency_iso_3}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllCurrency() {
    return await instance({
      url: `reference/currency/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllCurrencyPair() {
    return await instance({
      url: `reference/currencyPair/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllEntitie() {
    return await instance({
      url: `reference/entity/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllEntitieResponse() {
    return await instance({
      url: `reference/entityResponse/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllOnboardingRule() {
    return await instance({
      url: `reference/internal/onboardingRule/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllSecretQuestions() {
    return await instance({
      url: `reference/internal/secretQuestion/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllCountry(apiKey?: string) {
    // const x = sessionStorage.getItem('apiKey');
    return await instance({
      url: `reference/country/getAll`,
      method: REQUEST_METHOD.GET,
      headers: {
        'x-api-key': apiKey ? apiKey : localStorage.getItem('apiKey'),
      },
    });
  }

  static async _getAllPrefunds(page: number, size: number, role?: ROLS) {
    return await instance({
      url:
        role === ROLS.ADMIN
          ? `core/internalAdmin/preFund/getAll?page=${page}&size=${size}`
          : `core/agentPreFund/getAll/byAgent?page=${page}&size=${size}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllFunds(page: number, size: number, role?: ROLS) {
    return await instance({
      url:
        role === ROLS.ADMIN
          ? `core/internal/fund/getAll?page=${page}&size=${size}`
          : `core/agentFund/getAll/byAgent?page=${page}&size=${size}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getSingleFund(
    reference_id?: string,
    email?: string,
    role?: ROLS
  ) {
    return await instance({
      url:
        role === ROLS.AGENT
          ? `core/agentPreFund/get`
          : `core/internal/preFund/get?reference_id=${reference_id}`,
      method: REQUEST_METHOD.GET,
      headers:
        role === ROLS.AGENT ? {'Reference-ID': reference_id} : {Email: email},
    });
  }

  static async _getSingleFundByStatus(
    value?: CURRENCY,
    page?: number,
    limit?: number
  ) {
    return await instance({
      url:
        value === CURRENCY.USD
          ? `core/agentPreFund/getAll/byAgent/byIsVerified?page=${page}&size=${limit}`
          : `core/agentPreFund/getAll/byAgent/byIsVerified/byIsApproved?page=${page}&size=${limit}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getTotalFundsAgent() {
    return await instance({
      url: `core/internal/fund/getAllTotal/byAgent`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getSingleAgentDetail(email: string) {
    return await instance({
      url: `core/internal/fund/getAll/byAgent`,
      method: REQUEST_METHOD.GET,
      headers: {Email: email},
    });
  }

  static async _getSingleFundByCurrency(currency: string) {
    return await instance({
      url: `core/agentFund/get`,
      method: REQUEST_METHOD.GET,
      headers: {'Currency-ISO-3': currency},
    });
  }

  static async _getExchangeRate(
    base_currency_iso_3: string,
    term_currency_iso_3: string,
    rate_date: string
  ) {
    return await instance({
      url: `core/exchangeRate/get`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Base-Currency-ISO-3': base_currency_iso_3,
        'Term-Currency-ISO-3': term_currency_iso_3,
        'Rate-Date': rate_date,
      },
    });
  }

  static async _getAllFeeBands(page: number) {
    return await instance({
      url: `core/internal/agentFeeBand/getAll?page=${page}&size=${100}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllExchangeFund(page: number) {
    return await instance({
      url: `core/exchangeRate/getAll/byRateDate?page=${page}&size=100`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Start-Rate-Date': '01-01-2024',
        'End-Rate-Date': '01-01-2040',
      },
    });
  }

  static async _getAllBankExchangeFund2(page: number) {
    return await instance({
      url: `core/bankExchangeRate/getAll/byRateDate?page=${page}&size=100`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Start-Rate-Date': '01-01-2024',
        'End-Rate-Date': '01-01-2040',
      },
    });
  }

  static async _getAllBankExchangeFund3(page: number) {
    return await instance({
      url: `core/internal/bankFund/getAll?page=${page}&size=100`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllFeeBandsBanks(page: number) {
    return await instance({
      url: `core/internal/feeBand/getAll?page=${page}&size=${100}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllBankExchangeFund(page: number) {
    return await instance({
      url: `core/internal/bankExchangeFund/getAll?page=${page}&size=100`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllAgentFunds(page: number) {
    return await instance({
      url: `core/internal/agentFund/getAll?page=${page}&size=100`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllBankFUnd(page: number) {
    return await instance({
      url: `core/internal/bankFund/getAll?page=${page}&size=100`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getFeeBandByAgentAndCurrency(
    email?: string,
    currency?: string
  ) {
    if (email) {
      return await instance({
        url: `core/internal/feeBand/getAll/byAgent`,
        method: REQUEST_METHOD.GET,
        headers: {Email: email},
      });
    } else if (currency && email) {
      return await instance({
        url: `core/internal/feeBand/getAll/byAgent/byCurrency`,
        method: REQUEST_METHOD.GET,
        headers: {Email: email, 'Currency-ISO-3': currency},
      });
    }
  }

  static async _getFiliterAgentFeeBand(email?: string, currency?: string) {
    console.log(
      {email: email, currency: currency},
      'email and currency filter'
    );
    return await instance({
      url: `core/internal/agentFeeBand/getAll/byAgent/byCurrency`,
      method: REQUEST_METHOD.GET,
      headers: {Email: email, 'Currency-ISO-3': currency},
    });
  }

  static async _getFiliterExchangeRate(startDate?: any, endStart?: any) {
    var start = moment(startDate).format('DD-MM-YYYY');
    var end = moment(endStart).format('DD-MM-YYYY');

    return await instance({
      url: `core/exchangeRate/getAll/byRateDate?page=1&size=100`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Start-Rate-Date': start,
        'End-Rate-Date': end,
      },
    });
  }

  static async _getFilterBankExchangeRate(startDate?: any, endStart?: any) {
    var start = moment(startDate).format('DD-MM-YYYY');
    var end = moment(endStart).format('DD-MM-YYYY');

    return await instance({
      url: `core/bankExchangeRate/getAll/byRateDate?page=1&size=100`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Start-Rate-Date': start,
        'End-Rate-Date': end,
      },
    });
  }

  static async _getFiliterBankExchangeFund(
    bank_id?: any,
    bank_account_currency_iso_3?: any
  ) {
    return await instance({
      url: `core/internal/bankFund/get`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Bank-ID': bank_id,
        'Currency-ISO-3': bank_account_currency_iso_3,
      },
    });
  }

  static async _getFiliterBankFeeBandByCurrency(currency?: string) {
    return await instance({
      url: `core/internal/feeBand/getAll/byCurrency`,
      method: REQUEST_METHOD.GET,
      headers: {'Currency-ISO-3': currency},
    });
  }

  static async _getBankExchangeFund(Bank_ID?: string, currency?: string) {
    return await instance({
      url: `core/internal/bankExchangeFund/get`,
      method: REQUEST_METHOD.GET,
      headers: {'Bank-ID': Bank_ID, 'Currency-ISO-3': currency},
    });
  }

  static async _getAgentFundByEmail(email?: string, currency?: string) {
    return await instance({
      url: `core/internal/agentFund/get`,
      method: REQUEST_METHOD.GET,
      headers: {'Currency-ISO-3': currency, Email: email},
    });
  }

  static async _getBankFundByBankIdEMail(bank_id?: string, currency?: string) {
    return await instance({
      url: `core/internal/bankFund/get`,
      method: REQUEST_METHOD.GET,
      headers: {'Bank-ID': bank_id, 'Currency-ISO-3': currency},
    });
  }

  static async _getSampleOrder(data: ICurrencyConversion) {
    const X_API_KEY = localStorage.getItem('apiKey');
    return await instance({
      url: `core/web/getSampleOrder`,
      method: REQUEST_METHOD.POST,
      data: data,
      headers: {
        'x-api-key': X_API_KEY,
      },
    });
  }

  static async _getProfile() {
    // const email = sessionStorage.getItem("email");
    // const X_API_KEY = sessionStorage.getItem("apiKey");
    // console.log(email, "em");
    return await instance({
      url: `/internal/account/get`,
      method: REQUEST_METHOD.GET,
      // headers: {
      //   Email: email,
      //   "x-api-key": X_API_KEY,
      // },
    });
  }

  static async _getOrders(page: number, size: number) {
    return await instance({
      url: `/core/order/getAll/byAgent?page=${page}&size=${size}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getTransactions(page: number, size: number) {
    return await instance({
      url: `/core/internal/order/getAll?page=${page}&size=${size}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getTransactionDetail(orderId: string) {
    return await instance({
      url: `/core/order/get`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Order-ID': orderId,
      },
    });
  }

  static async _filterOrders(
    statuses: Array<string>,
    start_created_date: string,
    end_created_date: string,
    page: number,
    size: number
  ) {
    return await instance({
      url: `/core/order/getAll/byAgent?page=${page}&size=${size}`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getUserProfile() {
    return await instance({
      url: `/core/userProfile/get`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getOnboardingProfile() {
    return await instance({
      url: `/core/onboardingProfile/get`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getUserRoleRights() {
    return await instance({
      url: `/roleRight/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _cancelOrderByAgent(id: string) {
    return await instance({
      url: `/payout/cancel/byAgent`,
      method: REQUEST_METHOD.POST,
      headers: {
        'Agent-Order-ID': id,
      },
    });
  }

  static async _downloadOrderReport(payload: {
    start_date: string;
    end_date: string;
    bank_id: string;
  }) {
    return await instance({
      url: `/core/order/csv/exportAll/byExecutionBank/byCreatedDate`,
      method: REQUEST_METHOD.GET,
      headers: {
        'Execution-Bank-ID': payload.bank_id,
        'Start-Created-Date': payload.start_date,
        'End-Created-Date': payload.end_date,
      },
    });
  }

  static async _createOrderByAgent(data: IAddOrder) {
    return await instance({
      url: `/payout/add`,
      method: REQUEST_METHOD.POST,
      data: {...data},
      headers: {
        'Require-Rate-Confirmation': String(false),
      },
    });
  }

  static async _getOrdersByStatusAndDate(
    statuses: string[],
    start_execution_date: string,
    end_execution_date: string,
    page: number,
    size: number
  ) {
    const baseUrl = '/core/order/getAll/byAgent/byStatus/byCreatedDate';
    const queryString = statuses?.map((value) => `statuses=${value}`).join('&');
    const dateString = `start_created_date=${start_execution_date}&end_created_date=${end_execution_date}&page=${page}&size=${size}`;
    const url = `${baseUrl}?${queryString}&${dateString}`;
    return await instance({
      url,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllBanks() {
    return await instance({
      url: `/account/isVerified`,
      method: REQUEST_METHOD.GET,
      headers: {
        Email: sessionStorage.getItem('email'),
      },
    });
  }

  static async _getAllStewardBanks() {
    return await instance({
      url: `/reference/bank/getAll`,
      method: REQUEST_METHOD.GET,
    });
  }

  static async _getAllAvailableBanks() {
    return await instance({
      url: `/reference/bank/getAll`,
      method: REQUEST_METHOD.GET,
      headers: {
        Email: sessionStorage.getItem('email'),
      },
    });
  }

  static async _getUserVerifiedStatus() {
    return await instance({
      url: `/account/isVerified`,
      method: REQUEST_METHOD.GET,
      headers: {
        Email: sessionStorage.getItem('email'),
      },
    });
  }

  static async _addQuestionairre(data: CorporateData) {
    return await instance({
      url: `/core/onboardingQuestionnaire/add`,
      method: REQUEST_METHOD.POST,
      data,
    });
  }

  static async _updateQuestionairre(data: UpdateCorporateData) {
    return await instance({
      url: `/core/onboardingQuestionnaire/update`,
      method: REQUEST_METHOD.PATCH,
      data,
    });
  }

  static async _getQuestionairre() {
    return await instance({
      url: `/core/onboardingQuestionnaire/get`,
      method: REQUEST_METHOD.GET,
    });
  }
}
