import {message} from 'antd';
import {useMutation, useQuery} from 'react-query';
import {toast} from 'react-toastify';
import API_SERVICE from '../../services/api/service';
import {instance} from '../../services/constants/instance';
import {
  GET_API_KEY,
  GET_DEVELOPER_PROFILE,
  GET_USER_VERIFIED_STATUS,
} from '../../services/react-query/constants';
import {ErrorToast} from '../../services/toast';
import {REQUEST_METHOD} from '../../utils/types';

const headers = {'x-api-key': localStorage.getItem('apiKey')};
const devToken = sessionStorage.getItem('token');

export const useLogin = () => {
  const login_user = useMutation((loginData: any) => {
    return instance({
      url: `/login`,
      method: REQUEST_METHOD.POST,
      data: loginData,
    });
  }, {});
  return {login_user};
};

export const useForgotPassword = () => {
  const forgot = useMutation(
    (data: {email: string; apiKey?: string}) => {
      console.log(data?.email, 'to call');
      return instance({
        url: `password/forgot`,
        method: REQUEST_METHOD.POST,
        headers: {
          ...headers,
          Email: data?.email || localStorage.getItem('forgot_password_email'),
          'x-api-key': data?.apiKey || localStorage.getItem('apiKey'),
        },
      });
    },
    {
      onSuccess: (data: any) => {
        message.success(data?.data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.errors[0]);
      },
    }
  );
  return {forgot};
};

export const useVerifyOtp = () => {
  const verifyOtp = useMutation(
    (data: {otp: string; email: string; apiKey?: string}) => {
      return instance({
        url: `password/forgot/OTP`,
        method: REQUEST_METHOD.POST,
        headers: {
          ...headers,
          Email: data?.email,
          OTP: data?.otp,
          'x-api-key': data?.apiKey || localStorage.getItem('apiKey'),
        },
      });
    },
    {
      onSuccess: (data: any) => {
        message.success(data?.data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.errors[0]);
      },
    }
  );
  return {verifyOtp};
};

export const useResetPassword = () => {
  const reset = useMutation(
    (data: {
      confirm_new_password: string;
      email: string;
      new_password: string;
      token: string;
    }) => {
      return instance({
        url: `password/forgot/reset`,
        method: REQUEST_METHOD.POST,
        data: {
          confirm_new_password: data.confirm_new_password,
          email: data.email,
          new_password: data.new_password,
        },
        headers: {
          Authorization: `Bearer ${data?.token}`,
        },
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.errors[0]);
      },
    }
  );
  return {reset};
};

export const useLogout = () => {
  const logout = useMutation(
    () => {
      message.loading('logging out...');
      const token: string | any = sessionStorage.getItem('adminToken');
      const devToken = sessionStorage.getItem('token');
      return instance({
        url: `account/signOut`,
        method: REQUEST_METHOD.POST,
        headers: {
          ...headers,
          Authorization: `Bearer ${token || devToken}`,
        },
      });
    },
    {
      onSuccess: (data: any) => {
        message.success(data?.data?.message);
        if (window.location.href.includes('developer-portal')) {
          window.location.replace(`/developer-portal-login`);
          sessionStorage.remove('token');
        }
        if (window.location.href.includes('admin-portal')) {
          window.location.replace(`/admin-portal/login`);
          sessionStorage.remove('adminToken');
        }
      },
      onError: (error: any) => {
        sessionStorage.clear();
        localStorage.clear();
        if (window.location.href.includes('developer-portal')) {
          window.location.replace(`/developer-portal-login`);
          sessionStorage.remove('token');
        }
        if (window.location.href.includes('admin-portal')) {
          window.location.replace(`/admin-portal/login`);
          sessionStorage.remove('adminToken');
        }
      },
    }
  );
  return {logout};
};

export const useProfileOtp = () => {
  const profileOtp = useMutation(
    () => {
      return instance({
        url: `password/reset`,
        method: REQUEST_METHOD.POST,
        headers: {Authorization: devToken},
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.errors[0]);
      },
    }
  );
  return {profileOtp};
};

export const useProfilePasswordReset = () => {
  const profilePassword = useMutation(
    (data: {
      new_password: 'string';
      confirm_new_password: 'string';
      otp: string;
    }) => {
      return instance({
        url: `password/reset/OTP`,
        method: REQUEST_METHOD.POST,
        data,
        headers: {'X-Forwarded-Authorization': devToken, OTP: data.otp},
      });
    },
    {
      onSuccess: (data: any) => {
        toast.success(data?.data?.message);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.errors[0]);
      },
    }
  );
  return {profilePassword};
};

export const useGetProfile = () => {
  console.log('using');
  const {data, isFetching, isLoading, refetch} = useQuery({
    queryKey: [GET_DEVELOPER_PROFILE],
    queryFn: () => API_SERVICE._getProfile(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        ErrorToast(errors[0]);
      } else if (typeof errors === 'string') {
        ErrorToast(errors);
      }
    },
  });

  return {
    data,
    isFetching,
    isLoading,
    refetch,
  };
};

// password/forgot/reset

export const useResetDevPassword = () => {
  const reset = useMutation(
    (data: {
      confirm_new_password: string;
      new_password: string;
      token: string | any;
    }) => {
      return instance({
        url: `password/forgot/reset`,
        method: REQUEST_METHOD.POST,
        data: {
          confirm_new_password: data.confirm_new_password,
          new_password: data.new_password,
        },
        headers: {
          // Authorization: `Bearer ${data?.token}`,
          'X-Forwarded-Authorization': `${data?.token}`,
        },
      });
    },
    {
      onSuccess: (data: any) => {
        message.success(data?.data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.errors[0]);
      },
    }
  );
  return {reset};
};

export const useGetUserVerifiedStatus = () => {
  const {data, isFetching, isLoading, refetch} = useQuery({
    queryKey: [GET_USER_VERIFIED_STATUS],
    queryFn: () => API_SERVICE._getUserVerifiedStatus(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        message.error(errors[0]);
      } else if (typeof errors === 'string') {
        message.error(errors);
      }
    },
  });
  const verifiedStatus = data;
  return {
    verifiedStatus,
    isFetching,
    isLoading,
    refetch,
  };
};

export const useGetApiKey = () => {
  const {data, isFetching, isLoading, refetch} = useQuery({
    queryKey: [GET_API_KEY],
    queryFn: () => API_SERVICE._getApiKey(),
    onSuccess: (data) => {
      if (data) {
        localStorage.setItem('apiKey', data?.data?.data);
        sessionStorage.setItem('apiKey', data?.data?.data);
      }
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        message.error(errors[0]);
      } else if (typeof errors === 'string') {
        message.error(errors);
      }
    },
  });
  const apiKey = data?.data?.data;
  return {
    isFetching,
    isLoading,
    refetch,
    apiKey,
    data,
  };
};

export const useApiKey = () => {
  const getApiKey = useMutation(() => API_SERVICE._getApiKey(), {
    onSuccess: (data: any) => {
      if (data) {
        localStorage.setItem('apiKey', data?.data?.data);
        sessionStorage.setItem('apiKey', data?.data?.data);
      }
    },
    onError: (error: any) => {},
  });
  return {getApiKey};
};
